const gameData = [
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-1.png',
    like: '70%',
    play: '60k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-2.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-3.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-4.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
  {
    name: 'Supermarket shopping',
    cover: '/img/dummy/game-cover-5.png',
    like: '71%',
    play: '58k',
    id: Math.floor(Date.now() * Math.random()),
  },
];

export default gameData;

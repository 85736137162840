import React from 'react';

const EditAlt = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8335 13.8333C13.2937 13.8333 13.6668 14.2064 13.6668 14.6667C13.6668 15.1269 13.2937 15.5 12.8335 15.5H1.16683C0.706592 15.5 0.333496 15.1269 0.333496 14.6667C0.333496 14.2064 0.706592 13.8333 1.16683 13.8333H12.8335ZM12.0026 0.987431L13.1794 2.16419C13.8293 2.8141 13.8293 3.86781 13.1794 4.51772L6.31496 11.3822C6.18765 11.5095 6.02248 11.592 5.84425 11.6175L2.00016 12.1667L2.54932 8.32258C2.57478 8.14435 2.65736 7.97918 2.78467 7.85187L9.64911 0.987431C10.299 0.337523 11.3527 0.337523 12.0026 0.987431ZM10.826 2.16419L4.15783 8.83231L3.9619 10.205L5.3346 10.0091L12.0027 3.34095L10.826 2.16419Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditAlt;

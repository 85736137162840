import React from 'react';

const Xls = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75 0C3.0625 0 2.5 0.5625 2.5 1.25V18.75C2.5 19.4375 3.0625 20 3.75 20H16.25C16.9375 20 17.5 19.4375 17.5 18.75V5L12.5 0H3.75Z"
        fill="#E2E5E7"
      />
      <path d="M13.75 5H17.5L12.5 0V3.75C12.5 4.4375 13.0625 5 13.75 5Z" fill="#B0B7BD" />
      <path d="M17.5 8.75L13.75 5H17.5V8.75Z" fill="#CAD1D8" />
      <path
        d="M15 16.25C15 16.5938 14.7188 16.875 14.375 16.875H0.625C0.28125 16.875 0 16.5938 0 16.25V10C0 9.65625 0.28125 9.375 0.625 9.375H14.375C14.7188 9.375 15 9.65625 15 10V16.25Z"
        fill="#84BD5A"
      />
      <path
        d="M4.38822 12.7424L5.25759 11.653C5.51759 11.313 6.0226 11.7474 5.7376 12.0724C5.43822 12.4274 5.12322 12.8124 4.82322 13.2068L5.84259 14.4761C6.11759 14.8511 5.56822 15.2105 5.29822 14.8405L4.37822 13.6611L3.47384 14.8661C3.21884 15.2305 2.65384 14.8211 2.93884 14.4811L3.94322 13.2068C3.62822 12.8118 3.32322 12.4274 3.01884 12.0724C2.70447 11.6974 3.28884 11.313 3.51884 11.6636L4.38822 12.7424Z"
        fill="white"
      />
      <path
        d="M6.45972 11.8421C6.45972 11.6771 6.59972 11.5371 6.77472 11.5371C6.93472 11.5371 7.06972 11.6777 7.06972 11.8421V14.3459H8.42909C8.91847 14.3459 8.92909 15.0002 8.42909 15.0002H6.77534C6.60034 15.0002 6.46034 14.8759 6.46034 14.6959V11.8421H6.45972Z"
        fill="white"
      />
      <path
        d="M9.37626 12.2921C9.49126 11.3221 10.955 11.1477 11.645 11.6777C11.985 11.9521 11.625 12.3871 11.325 12.1671C10.955 11.9327 10.1156 11.8227 10.0106 12.3415C9.87564 13.1615 12.0494 12.6921 12.0144 14.0215C11.9794 15.2908 10.1406 15.3202 9.45064 14.7508C9.28501 14.6158 9.29064 14.3965 9.38064 14.2608C9.51064 14.1315 9.65501 14.0865 9.82564 14.2265C10.235 14.5058 11.29 14.7158 11.3644 14.0058C11.3 13.2671 9.22126 13.7115 9.37626 12.2921Z"
        fill="white"
      />
      <path
        d="M14.375 16.875H2.5V17.5H14.375C14.7188 17.5 15 17.2188 15 16.875V16.25C15 16.5938 14.7188 16.875 14.375 16.875Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};

export default Xls;

import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import tw from 'twin.macro';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import MoreVertical from 'src/icons/MoreVertical';
import Doc from 'src/icons/Doc';
import BookOpen from 'src/icons/BookOpen';
import Pdf from 'src/icons/Pdf';
import Star from 'src/icons/Star';
import PublishCard from 'src/components/publishCard';
import Quiz from 'src/components/quiz';

const Preview = () => {
  return (
    <PreviewContainer>
      <p>
        A simple and intuitive way of learning how to use 3D software such as Cinema 4D is to focus
        on the creation of characters. Through the use of basic shapes and some tools, you can
        achieve a complete understanding of the program that will help you face increasingly complex
        projects.
      </p>
      <p>
        Illustrator and 3D designer Aarón Martínez develops a complete methodology to learn how to
        use Cinema 4D in 6 courses, in an intuitive way, allowing for grand results in little time.
      </p>

      <PublishCard className="my-12">
        <div className="body">
          <Quiz />
        </div>
        <div className="footer">
          <div className="custom-pagination">
            <Pagination
              defaultCurrent={1}
              total={240}
              showSizeChanger={false}
              itemRender={(page, type, originalElement) => {
                return type === 'prev' ? (
                  <ArrowLeftOutlined />
                ) : type === 'next' ? (
                  <ArrowRightOutlined />
                ) : (
                  originalElement
                );
              }}
            />
          </div>
        </div>
      </PublishCard>
      <p>
        A simple and intuitive way of learning how to use 3D software such as Cinema 4D is to focus
        on the creation of characters. Through the use of basic shapes and some tools, you can
        achieve a complete understanding of the program that will help you face increasingly complex
        projects.
      </p>
      <p>
        Illustrator and 3D designer Aarón Martínez develops a complete methodology to learn how to
        use Cinema 4D in 6 courses, in an intuitive way, allowing for grand results in little time.
      </p>

      <PublishCard className="my-12">
        <div className="body">
          <AssignmentWrapper>
            <div className="class-work">
              <div className="header">
                <h4 className="flex items-center">
                  <span className="mr-2">
                    <BookOpen />
                  </span>
                  Curriki Studio Class Work
                </h4>
                <div className="action">
                  <MoreVertical />
                </div>
              </div>
              <div className="body">
                <p>
                  A simple and intuitive way of learning how to use 3D software such as Cinema 4D is
                  to focus on the creation of characters. Through the use of basic shapes and some
                  tools, you can achieve a complete understanding of the program that will help you
                  face increasingly complex projects.
                </p>
              </div>
            </div>

            <img className="my-4" src="/img/dummy/workspace-g.png" alt="workspace" />

            <div className="class-work-info grid grid-cols-2 xl:w-4/6 xl:gap-3">
              <p>
                Average Time Watched: <span>20 min</span>
              </p>
              <p>
                Total Students: <span>10/50 (20%)</span>
              </p>
              <p>
                Total Hours: <span>22 Hours</span>
              </p>
              <p>
                Total Times: <span>100</span>
              </p>
            </div>
          </AssignmentWrapper>
        </div>
      </PublishCard>

      <DownloadList className="mb-12">
        <h4 className="title mb-4">Downloads</h4>
        <div className="item">
          <span className="mr-2">
            <Pdf />
          </span>
          Adison_Gouse_assignment_1b.ppt
        </div>
        <div className="item">
          <span className="mr-2">
            <Doc />
          </span>
          Gouse_assignment_1b.doc
        </div>
        <div className="item">
          <span className="mr-2">
            <Pdf />
          </span>
          Physics_384oassignment_1b.pdf
        </div>
      </DownloadList>

      <RecomendedList className="mb-12">
        <div className="header">
          <h4 className="title">Recommended Content</h4>
          <div className="view-more">View More</div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <RecommendedCard>
            <div className="cover">
              <div className="tag primary">Design</div>
              <img src="/img/dummy/nasa-1.jfif" alt="card-cover" />
            </div>
            <h4 className="title">Build spaceshuttle in the space and help the planet</h4>
            <div className="action">
              <div className="ratting">
                <Star className="mr-2" /> <span>4.8/5</span>
              </div>
              <button className="assign">Assign</button>
            </div>
          </RecommendedCard>
          <RecommendedCard>
            <div className="cover">
              <div className="tag secondary">Design</div>
              <img src="/img/dummy/nasa-2.jfif" alt="card-cover" />
            </div>
            <h4 className="title">STEMuli game engine in the making robots</h4>
            <div className="action">
              <div className="ratting">
                <Star className="mr-2" /> <span>4.8/5</span>
              </div>
              <button className="assign">Assign</button>
            </div>
          </RecommendedCard>
        </div>
      </RecomendedList>
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  & > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.4px;
    ${tw`mb-4`};
  }
`;
const AssignmentWrapper = styled.div`
  .class-work {
    background: rgba(0, 82, 204, 0.05);
    mix-blend-mode: normal;
    border-radius: 8px;
    .header {
      border-color: rgba(143, 146, 161, 0.1);
      ${tw`flex items-center justify-between px-6 py-3 border-b`};
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        ${tw`text-black-1`};
      }
      .action {
        font-size: 20px;
        ${tw`text-primary`};
      }
    }
    .body {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      ${tw`px-6 py-4 text-black-1`};
    }
  }
`;

const DownloadList = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  .title {
    font-weight: 600;
    ${tw`text-black-1`};
  }
  .item {
    ${tw`flex items-center text-gray-6 my-4`};
  }
`;

const RecomendedList = styled.div`
  .header {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    ${tw`flex items-center justify-between mb-6`};
    .title {
      font-weight: 600;
      ${tw`text-black-1`};
    }
    .view-more {
      text-align: right;
      letter-spacing: -0.4px;
      ${tw`text-primary cursor-pointer`};
    }
  }
`;

const RecommendedCard = styled.div`
  font-style: normal;
  ${tw`rounded-xl bg-white p-4 text-black-1`};
  .cover {
    ${tw`relative`};
    img {
      height: 160px;
      ${tw`rounded-xl mb-6 w-full`};
    }
    .tag {
      width: 57px;
      height: 28px;
      left: 10px;
      top: 10px;
      font-size: 14px;
      font-weight: normal;
      ${tw`rounded-lg text-white absolute grid place-items-center`};
      &.primary {
        ${tw`bg-red-2`};
      }
      &.secondary {
        ${tw`bg-gray-14`};
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .action {
    ${tw`flex justify-between items-center mt-4`};
    font-size: 14px;
    line-height: 24px;
    .ratting {
      ${tw`flex items-center`};
    }
    .assign {
      width: 59px;
      height: 28px;
      font-style: normal;
      font-weight: 500;
      ${tw`border-0 outline-none flex justify-center items-center rounded-lg bg-primary text-white`};
    }
  }
`;
export default Preview;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import LinkIcon from 'src/icons/LinkIcon';
import Doc from 'src/icons/Doc';
import Pdf from 'src/icons/Pdf';
import Xls from 'src/icons/Xls';
import Png from 'src/icons/Png';
import Delete from 'src/icons/Delete';

const Files = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<File[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      setFileList([...fileList, ...e.target.files]);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      setFileList([...fileList, ...e.dataTransfer.files]);
    }
  };

  const detectFile = (fileName = '') => {
    switch (fileName.split('.').pop()?.toLowerCase()) {
      case 'doc':
      case 'docx':
        return <Doc className="mr-2" />;
      case 'png':
        return <Png className="mr-2" />;
      case 'pdf':
        return <Pdf className="mr-2" />;
      case 'xls':
        return <Xls className="mr-2" />;
      default:
        return null;
    }
  };

  const handleDelete = (i: number) => {
    fileList.splice(i, 1);
    setFileList([...fileList]);
  };

  return (
    <FilesContainer>
      <h4>Upload Files</h4>
      <div
        className="drag-and-drop-container mb-8"
        onClick={() => fileRef.current && fileRef.current.click()}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <span className="flex items-center">
          <LinkIcon className="mr-2" /> Browse files or drag and drop here
        </span>
        <input
          type="file"
          className="hidden"
          multiple={true}
          accept="pdf,doc,docx,png,xls"
          ref={fileRef}
          onChange={handleFileChange}
        />
      </div>
      <div className="file-list">
        {fileList.map((file, i) => (
          <div className="file" key={i}>
            <span className="file-name">
              {detectFile(file.name)} {file.name}
            </span>
            <span className="delete" onClick={() => handleDelete(i)}>
              <Delete />
            </span>
          </div>
        ))}
      </div>
    </FilesContainer>
  );
};

const FilesContainer = styled.div`
  ${tw`mb-16`};
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    ${tw`text-black-2 mb-4`};
  }
  .drag-and-drop-container {
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    max-width: 100%;
    height: 82px;
    border-radius: 5px;
    ${tw`border border-dashed border-primary flex items-center justify-center text-gray-6 cursor-pointer`};
    span {
      svg {
        font-size: 22px;
      }
    }
  }
  .file-list {
    .file {
      font-size: 16px;
      line-height: 20px;
      ${tw`flex items-center justify-between`};
      .file-name {
        ${tw`flex items-center justify-between mb-3`};
      }
      .delete {
        font-size: 20px;
        ${tw`cursor-pointer`};
      }
    }
  }
`;
export default Files;

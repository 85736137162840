import React from 'react';

const Notification = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0837 6.83333V5.08333C11.0837 2.82817 9.25549 1 7.00033 1C4.74516 1 2.91699 2.82817 2.91699 5.08333V6.83333C2.91699 8.75833 1.16699 9.225 1.16699 10.3333C1.16699 11.325 3.44199 12.0833 7.00033 12.0833C10.5587 12.0833 12.8337 11.325 12.8337 10.3333C12.8337 9.225 11.0837 8.75833 11.0837 6.83333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00038 13.2497C6.41063 13.2497 5.85646 13.2299 5.33496 13.1914C5.56314 13.9193 6.23757 14.4147 7.00038 14.4147C7.76318 14.4147 8.43761 13.9193 8.66579 13.1914C8.14429 13.2299 7.59013 13.2497 7.00038 13.2497Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Notification;

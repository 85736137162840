import React from 'react';

const Png = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75 0C3.0625 0 2.5 0.5625 2.5 1.25V18.75C2.5 19.4375 3.0625 20 3.75 20H16.25C16.9375 20 17.5 19.4375 17.5 18.75V5L12.5 0H3.75Z"
        fill="#E2E5E7"
      />
      <path d="M13.75 5H17.5L12.5 0V3.75C12.5 4.4375 13.0625 5 13.75 5Z" fill="#B0B7BD" />
      <path d="M17.5 8.75L13.75 5H17.5V8.75Z" fill="#CAD1D8" />
      <path
        d="M15 16.25C15 16.5938 14.7188 16.875 14.375 16.875H0.625C0.28125 16.875 0 16.5938 0 16.25V10C0 9.65625 0.28125 9.375 0.625 9.375H14.375C14.7188 9.375 15 9.65625 15 10V16.25Z"
        fill="#A066AA"
      />
      <path
        d="M2.37561 11.8417C2.37561 11.6767 2.50499 11.4961 2.71499 11.4961H3.86999C4.51936 11.4961 5.10436 11.9311 5.10436 12.7655C5.10436 13.5555 4.51936 13.9948 3.86999 13.9948H3.03436V14.6548C3.03436 14.8755 2.89561 14.9998 2.71499 14.9998C2.54999 14.9998 2.37561 14.8755 2.37561 14.6548V11.8417ZM3.03436 12.1261V13.3705H3.86936C4.20374 13.3705 4.46874 13.0755 4.46874 12.7655C4.46874 12.4155 4.20374 12.1261 3.86936 12.1261H3.03436Z"
        fill="white"
      />
      <path
        d="M5.74147 11.8917C5.74147 11.7111 5.78147 11.5367 6.04147 11.5367C6.22084 11.5367 6.26147 11.5817 6.39584 11.7111L8.04959 13.7805V11.8417C8.04959 11.6767 8.19397 11.4961 8.36459 11.4961C8.54459 11.4961 8.71897 11.6767 8.71897 11.8417V14.6548C8.71897 14.8755 8.58397 14.9592 8.45397 14.9998C8.27959 14.9998 8.19397 14.9598 8.04959 14.8255L6.39584 12.7261V14.6555C6.39584 14.8761 6.26084 15.0005 6.08084 15.0005C5.90084 15.0005 5.74084 14.8761 5.74084 14.6555V11.8917H5.74147Z"
        fill="white"
      />
      <path
        d="M12.4782 14.6153C12.1232 14.9096 11.6882 15.0353 11.2488 15.0353C10.1988 15.0353 9.45447 14.4353 9.45447 13.2459C9.45447 12.2365 10.2395 11.4521 11.2932 11.4521C11.6882 11.4521 12.1226 11.5871 12.4326 11.8921C12.7376 12.1915 12.2976 12.6365 11.9982 12.3721C11.8132 12.1915 11.5532 12.0571 11.2932 12.0571C10.6888 12.0571 10.1045 12.5415 10.1045 13.2459C10.1045 13.9859 10.5938 14.4353 11.2488 14.4353C11.5532 14.4353 11.8132 14.3453 11.9982 14.2103V13.5965H11.2488C10.8038 13.5965 10.8488 12.9859 11.2488 12.9859H12.2482C12.4332 12.9859 12.6026 13.1265 12.6026 13.2815V14.3459C12.6026 14.4353 12.5632 14.5196 12.4782 14.6153Z"
        fill="white"
      />
      <path
        d="M14.375 16.875H2.5V17.5H14.375C14.7188 17.5 15 17.2188 15 16.875V16.25C15 16.5938 14.7188 16.875 14.375 16.875Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};

export default Png;

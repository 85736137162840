import React, { FC, ReactElement } from 'react';
import ChevronRight from 'src/icons/ChevronRight';

interface IProps {
  light?: boolean;
  className?: string;
  rightContent?: ReactElement | null;
}

// TODO - plug in actual class name for physics

const AppBar: FC<IProps> = ({ light, className = '', rightContent = null }): ReactElement => {
  return (
    <div className={`app-bar ${className}`}>
      <div className="flex items-center">
        <img src="/img/dark.png" className="mr-4" />
        <ChevronRight />
        <span className="mr-4 ml-4">Courses</span>
        <ChevronRight />
        <span className={light ? 'ml-4 text-black font-semibold' : 'ml-4 text-white font-semibold'}>
          Algebra I
        </span>
      </div>
      <div className="app-bar-right">
        {rightContent ? rightContent : <button className="app-bar-btn">View as student</button>}
      </div>
    </div>
  );
};

export default AppBar;

import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Input, Popover, Pagination } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Close,
  SearchOutlined,
  Heart,
  GameControl,
  ArrowRight,
  DeleteBack,
  Signal,
} from 'src/icons';
import games from './gameData';

export type GameType = {
  name: string;
  cover: string;
  like: string;
  play: string;
  id: number | string;
};

type GameListPropsType = {
  open: true | false;
  onClose?: () => void;
  games?: GameType[];
  selectedGames?: GameType[];
  onSelect?: (game: GameType) => void;
  onRemove?: (id: string | number) => void;
};

const PopoverContent = () => {
  return (
    <PopoverContentWrapper className="popover-content">
      <div className="heading">
        <div className="flex justify-between">
          <h5>Playful Kit</h5>
          <button className="assign">Assign</button>
        </div>
      </div>
      <div className="info flex items-center">
        <span className="like flex items-center mr-2">
          <Heart color="currentColor" /> 78%
        </span>
        <span className="play flex items-center">
          <GameControl color="currentColor" /> 50k
        </span>
      </div>
      <div className="label flex items-center">
        <Signal /> <h5 className="ml-2">Beginner</h5>
      </div>
      <p>
        This game allows you to move in space and create a unique way to engage with the system to
        allow you see ways to be the best in the classroom and be served the best juice.
      </p>
    </PopoverContentWrapper>
  );
};

const GameList: React.FC<GameListPropsType> = ({
  open,
  onClose,
  selectedGames,
  onRemove,
  onSelect,
}) => {
  return (
    <div>
      {open ? <OverLay /> : null}
      <SlideContainer className={open ? 'open' : ''}>
        <div className="slide-heading">
          <div className="action-bar">
            <h2 className="title">Add Game</h2>
            <span onClick={onClose}>
              <Close />
            </span>
          </div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search library..."
            className="search-box"
          />
        </div>

        <div className="game-list mt-6">
          {games.map((game, i) => {
            return (
              <Popover
                key={game.id}
                content={<PopoverContent />}
                trigger="hover"
                placement="right"
                overlayClassName="game-popover"
              >
                <div className="game-item" key={game.id}>
                  <img className="cover" src={game.cover} alt="cover" />
                  <h4>
                    {game.name} {i}
                  </h4>
                  <div className="info">
                    <span>
                      <Heart /> {game.like}
                    </span>
                    <span>
                      <GameControl />
                      {game.play}
                    </span>
                  </div>
                  {!selectedGames?.includes(game) ? (
                    <button
                      className="btn-primary assign"
                      onClick={() => onSelect && onSelect(game)}
                    >
                      Assign Game{' '}
                      <span className="ml-3">
                        <ArrowRight />
                      </span>
                    </button>
                  ) : (
                    <button
                      className="btn-danger assign"
                      onClick={() => onRemove && onRemove(game.id)}
                    >
                      Remove Game{' '}
                      <span className="ml-3">
                        <DeleteBack />
                      </span>
                    </button>
                  )}
                </div>
              </Popover>
            );
          })}
        </div>
        <div className="custom-pagination text-center">
          <Pagination
            defaultCurrent={1}
            total={240}
            showSizeChanger={false}
            itemRender={(page, type, originalElement) => {
              return type === 'prev' ? (
                <ArrowLeftOutlined />
              ) : type === 'next' ? (
                <ArrowRightOutlined />
              ) : (
                originalElement
              );
            }}
          />
        </div>
      </SlideContainer>
    </div>
  );
};

const OverLay = styled.div`
  width: 100%;
  height: 100%;
  ${tw`fixed top-0 left-0 bg-black bg-opacity-80`};
`;
const SlideContainer = styled.div`
  width: 860px;
  max-width: 100%;
  height: 100%;
  right: -100%;
  &.open {
    right: 0;
  }
  ${tw`fixed top-0 bg-white p-8 transition-all `};
  .slide-heading {
    .back {
      background: rgba(143, 146, 161, 0.1);
      height: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      ${tw`rounded-xl focus:outline-none border-none inline-flex items-center px-2 text-black-2`};
      svg {
        font-size: 20px;
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }
    .action-bar {
      ${tw`flex items-center justify-between`};
      span {
        width: 40px;
        height: 40px;
        font-size: 15px;
        ${tw`rounded-full bg-red-1 bg-opacity-5 text-red-1 text-opacity-80 grid place-items-center cursor-pointer`};
      }
    }
    .search-box {
      width: 449px;
      height: 34px;
      max-width: 100%;
      ${tw`mt-6 rounded-md focus:outline-none border-black border-opacity-10 shadow-none`};
      .ant-input-prefix {
        ${tw`text-gray-5 mr-2`};
      }
    }
  }

  .game-list {
    overflow-y: scroll;
    height: 80%;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ${tw`grid grid-cols-4 gap-4 mb-8`};
    .game-item {
      ${tw`cursor-pointer`};
      .cover {
        height: 125px;
        width: 100%;
        object-fit: cover;
        ${tw`rounded-lg`};
      }
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        ${tw`my-3`};
      }

      .info {
        ${tw`flex items-center text-black-2`};
        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          ${tw`flex items-center`};
          &:first-child {
            ${tw`mr-10`};
          }
          svg {
            font-size: 18px;
            ${tw`mr-1`};
          }
        }
      }

      .assign {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        ${tw`mt-4 w-full focus:outline-none flex items-center justify-center py-2`};
      }
    }
  }
`;

const PopoverContentWrapper = styled.div`
  ${tw`p-10 text-white`};

  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    ${tw`text-white`};
  }
  .assign {
    width: 80px;
    height: 38px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    ${tw`rounded-lg inline-flex items-center justify-center focus:outline-none bg-blue-500`};
  }
  .info {
    ${tw`my-3`};
    .like,
    .play {
      svg {
        font-size: 19px;
        ${tw`mr-1`};
      }
    }
    .play {
      svg {
        ${tw`text-alternate`};
      }
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    ${tw`mt-8`};
  }
`;
export default GameList;

export { SlideContainer, OverLay };

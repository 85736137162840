import React from 'react';

const CheckRounded = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32093 9.26017L5.67718 7.63757C5.48066 7.44358 5.16409 7.44563 4.97009 7.64215C4.7761 7.83867 4.77815 8.15525 4.97467 8.34924L7.00744 10.3558C7.21645 10.5622 7.55743 10.5448 7.74435 10.3182L11.0449 6.31824C11.2207 6.10524 11.1905 5.7901 10.9775 5.61435C10.7645 5.4386 10.4494 5.4688 10.2736 5.68179L7.32093 9.26017Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33325 0.833252H9.66658C12.7042 0.833252 15.1666 3.29569 15.1666 6.33325V9.66658C15.1666 12.7042 12.7042 15.1666 9.66658 15.1666H6.33325C3.29569 15.1666 0.833252 12.7042 0.833252 9.66658V6.33325C0.833252 3.29569 3.29569 0.833252 6.33325 0.833252ZM6.33325 1.83325C3.84797 1.83325 1.83325 3.84797 1.83325 6.33325V9.66659C1.83325 12.1519 3.84797 14.1666 6.33325 14.1666H9.66659C12.1519 14.1666 14.1666 12.1519 14.1666 9.66659V6.33325C14.1666 3.84797 12.1519 1.83325 9.66659 1.83325H6.33325Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckRounded;

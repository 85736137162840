import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Select, { components } from 'react-select';
import DropDown from 'src/icons/DropDown';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDown />
    </components.DropdownIndicator>
  );
};

const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <span className="value-container">
        <span className="dot" />
        <span>{props.children}</span>
      </span>
    </components.SingleValue>
  );
};

const Control = (props: any) => {
  return (
    <components.Control {...props} className={`${props.selectProps?.value?.value}`}>
      {props.children}
    </components.Control>
  );
};

type PBProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  rightContent?: React.ReactNode | null;
};

const PublishCard: React.FC<PBProps> = ({
  className = '',
  children,
  title = 'Push to Live',
  subtitle = 'Push content to student immediately.',
  rightContent = null,
}) => {
  return (
    <PublishCardWrapper className={className}>
      <div className="header">
        <div className="header-info">
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
        <div className="header-action">
          {rightContent ? (
            rightContent
          ) : (
            <SelectWrapper>
              <Select
                components={{ DropdownIndicator, SingleValue, Control }}
                classNamePrefix="custom"
                options={[
                  { label: 'Published', value: 'published' },
                  { label: 'Unpublished', value: 'unpublished' },
                ]}
              />
            </SelectWrapper>
          )}
        </div>
      </div>
      {children}
    </PublishCardWrapper>
  );
};

const PublishCardWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Jost', sans-serif;
  font-weight: normal;
  ${tw`bg-white`};
  .header {
    height: 84px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    ${tw`px-6 py-4 flex items-center justify-between`};
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
    ,
    p {
      font-weight: 300;
      font-size: 14px;
    }
    &-action {
      width: 150px;
      text-align: end;
    }
  }
  .body {
    ${tw`px-6 py-4`};
  }
  .footer {
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
    .custom-pagination {
      background: rgba(250, 250, 251, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  .custom__control {
    border-radius: 16px;
    height: 40px;
    box-shadow: none !important;
    border: none !important;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    mix-blend-mode: normal;
    background: rgba(0, 135, 90, 0.03);
    .custom__single-value {
      width: calc(100% - 8px);
    }
    .value-container {
      ${tw`flex justify-around items-center`};
      .dot {
        width: 8px;
        height: 8px;
        display: flex;
        border-radius: 8px;
      }
    }
    &.published {
      background: rgba(0, 135, 90, 0.05);
      .dot {
        background: rgba(0, 135, 90);
      }
    }
    &.unpublished {
      background: rgba(250, 52, 52, 0.05);
      .dot {
        background: rgba(250, 52, 52);
      }
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export default PublishCard;

export { SelectWrapper, DropdownIndicator, SingleValue, Control };

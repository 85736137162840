import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Select from 'react-select';
import { Input, Tabs, Avatar, Pagination } from 'antd';
import { SearchOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AngleRight from 'src/icons/AngleRight';
import LinkIcon from 'src/icons/LinkIcon';
import CheckRounded from 'src/icons/CheckRounded';
import Clock from 'src/icons/Clock';
import CornerUpLeft from 'src/icons/CornerUpLeft';
import DeleteAlt from 'src/icons/DeleteAlt';
import EditAlt from 'src/icons/EditAlt';
import Download from 'src/icons/Download';

const Assignment = () => {
  const [selected, setSelected] = useState<null | number>(null);

  return (
    <AssignmentWrapper>
      {selected !== null ? (
        <div className="selected-action">
          <button onClick={() => setSelected(null)}>
            <CornerUpLeft /> Back to Assignment
          </button>
          <div className="action">
            <span>
              <EditAlt />
            </span>
            <span>
              <DeleteAlt />
            </span>
          </div>
        </div>
      ) : null}
      <div className="info-card mb-8">
        <h4>Homework Instructions</h4>
        <p>
          Build game inside the STEMuli’s game engine and adopt a new engine on how to remove the
          obstacles. Follow this url:
          <a href="http://stemuli.net/game-engine/crypto/stem-coin">
            http://stemuli.net/game-engine/crypto/stem-coin
          </a>
        </p>
      </div>

      {selected !== null ? (
        <SubmissionContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Submitted" key="1" tabKey="1" className="submission-list">
              <div className="filter-container">
                <div className="select">
                  <Select placeholder="Filter" classNamePrefix="filter" />
                </div>
                <div className="search">
                  <Input prefix={<SearchOutlined />} placeholder="Search by name" />
                </div>
              </div>

              <div className="data-table">
                <div className="data-table-header">
                  <div className="data-table-col h">
                    <input type="checkbox" />
                    <span>Name</span>
                  </div>
                  <div className="data-table-col h">Grade</div>
                  <div className="data-table-col h">&nbsp;</div>
                </div>
                <div className="data-table-row">
                  <div className="data-table-col">
                    <input type="checkbox" />
                    <Avatar size={48}>W</Avatar>
                    <span className="name">Walter Gibson</span>
                  </div>
                  <div className="data-table-col">
                    <span className="grade">85</span>
                  </div>
                  <div className="data-table-col">
                    <span className="download">
                      <Download />
                    </span>
                  </div>
                </div>
                <div className="data-table-row">
                  <div className="data-table-col">
                    <input type="checkbox" />
                    <Avatar size={48}>W</Avatar>
                    <span className="name">
                      Walter Gibson
                      <p>Submitted: Nov 12, 2012. 8:00PM</p>
                    </span>
                  </div>
                  <div className="data-table-col">
                    <span className="grade">85</span>
                  </div>
                  <div className="data-table-col">
                    <span className="download">
                      <Download />
                    </span>
                  </div>
                </div>
                <div className="data-table-row">
                  <div className="data-table-col">
                    <input type="checkbox" />
                    <Avatar className="active" size={48} src="/img/dummy/user-4.png">
                      W
                    </Avatar>
                    <span className="name">Alejandro Holland</span>
                  </div>
                  <div className="data-table-col">
                    <span className="grade">85</span>
                  </div>
                  <div className="data-table-col">
                    <span className="download">
                      <Download />
                    </span>
                  </div>
                </div>
                <div className="data-table-row">
                  <div className="data-table-col">
                    <input type="checkbox" />
                    <Avatar className="not-active" size={48} src="/img/dummy/user-4.png">
                      R
                    </Avatar>
                    <span className="name">Rose Foster</span>
                  </div>
                  <div className="data-table-col">
                    <span className="grade low">46</span>
                  </div>
                  <div className="data-table-col">
                    <span className="download">
                      <Download />
                    </span>
                  </div>
                </div>
              </div>
              <div className="custom-pagination">
                <Pagination
                  defaultCurrent={1}
                  total={240}
                  showSizeChanger={false}
                  itemRender={(page, type, originalElement) => {
                    return type === 'prev' ? (
                      <ArrowLeftOutlined />
                    ) : type === 'next' ? (
                      <ArrowRightOutlined />
                    ) : (
                      originalElement
                    );
                  }}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </SubmissionContainer>
      ) : (
        <div className="assignment-list mt-8">
          <div className="assignment-item">
            <div className="header">
              <h4>Laws of Motion</h4>
              <span className="action" onClick={() => setSelected(1)}>
                <AngleRight />
              </span>
            </div>
            <div className="body">I am asking you to develop a new way to introduce...</div>
            <div className="footer">
              <span className="label">
                <LinkIcon /> 34
              </span>
              <span className="label">
                <CheckRounded />
                2/10
              </span>
              <span className="label highlight">
                <Clock />8 days left
              </span>
            </div>
          </div>
          <div className="assignment-item">
            <div className="header">
              <h4>Understanding Physics and it’s nature</h4>
              <span className="action" onClick={() => setSelected(1)}>
                <AngleRight />
              </span>
            </div>
            <div className="body">I am asking you to develop a new way to introduce...</div>
            <div className="footer">
              <span className="label">
                <LinkIcon /> 2
              </span>
              <span className="label">
                <CheckRounded />
                2/10
              </span>
              <span className="label highlight">
                <Clock />8 days left
              </span>
            </div>
          </div>
          <div className="assignment-item">
            <div className="header">
              <h4>Building STEMuli 2.0 from the ground up</h4>
              <span className="action" onClick={() => setSelected(1)}>
                <AngleRight />
              </span>
            </div>
            <div className="body">I am asking you to develop a new way to introduce...</div>
            <div className="footer">
              <span className="label">
                <LinkIcon /> 0
              </span>
              <span className="label">
                <CheckRounded />
                2/10
              </span>
              <span className="label highlight">
                <Clock />8 days left
              </span>
            </div>
          </div>
        </div>
      )}
    </AssignmentWrapper>
  );
};

const AssignmentWrapper = styled.div`
  font-weight: normal;
  ${tw`mb-16`};
  .label {
    border-radius: 5px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    ${tw`px-2 py-1 inline-flex items-center justify-center text-gray-5`};
    &.highlight {
      ${tw`bg-alternate bg-opacity-10 text-alternate`};
    }
  }
  .selected-action {
    ${tw`flex items-center justify-between mb-6`};
    button {
      font-size: 14px;
      ${tw`rounded-full h-10 bg-gray-10 bg-opacity-10 border-none text-black-2 font-semibold inline-flex items-center px-4 focus:outline-none`};
      svg {
        font-size: 20px;
        ${tw`mr-2`};
      }
    }
    .action {
      ${tw`flex items-center`};
      span {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        ${tw`bg-white text-primary border border-gray-12 grid place-items-center mr-1.5 cursor-pointer`};
      }
    }
  }
  .assignment-list {
    .assignment-item {
      ${tw`py-4 my-4 border-b border-gray-11 border-opacity-10`};
      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        ${tw`flex justify-between items-center text-black-2 mb-4`};
        .action {
          font-size: 23px;
          ${tw`cursor-pointer`};
        }
      }
      .body {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        ${tw`text-gray-10 mb-3`};
      }
      .footer {
        ${tw`flex items-center`};
        svg {
          font-size: 16px;
          ${tw`mr-1`};
        }
      }
    }
  }
`;

const SubmissionContainer = styled.div`
  .ant-tabs-tab-active,
  .ant-tabs-tab {
    ${tw`text-primary`};
    .ant-tabs-tab-btn {
      font-weight: 600;
      font-size: 16px;
      ${tw`text-primary`};
    }
  }
  .ant-tabs-ink-bar {
    ${tw`bg-primary`};
  }
  .filter-container {
    ${tw`grid grid-cols-3 gap-1 mb-4`};
    .select {
      ${tw`col-span-1`};
      .filter__control {
        height: 40px;
        ${tw`border-gray-12 shadow-none! rounded-md`};
      }
      .filter__indicator-separator {
        display: none;
      }
    }
    .search {
      ${tw`col-span-2`};
      .ant-input-affix-wrapper {
        ${tw`py-0 px-3 rounded-md shadow-none! border-gray-12 text-gray-11 text-base`};
        height: 40px;
        input {
        }
      }
    }
  }
  .data-table-header,
  .data-table-row {
    grid-template-columns: 60% 30% 10%;
    ${tw`grid py-3 items-center`};
    input[type='checkbox'] {
      position: relative;
      width: 20px;
      height: 20px;
      ${tw`mr-2 cursor-pointer`};
    }
    .data-table-col {
      font-size: 14px;
      ${tw`flex items-center`};
      .ant-avatar {
        &.active,
        &.not-active {
          ${tw`border-2`};
        }
        &.active {
          ${tw`border-green-100`};
        }
        &.not-active {
          ${tw`border-red-1`};
        }
      }
      .name {
        font-weight: normal;
        line-height: 20px;
        width: calc(100% - 84px);
        ${tw`ml-2`};
        p {
          font-size: 12px;
          ${tw`text-gray-11`};
        }
      }

      .grade {
        width: 42px;
        height: 28px;
        ${tw`rounded-md bg-green-100 bg-opacity-10 text-green-100 grid place-items-center font-semibold`};
        &.low {
          ${tw`bg-red-1 bg-opacity-10 text-red-1`};
        }
      }

      .download {
        font-size: 20px;
        ${tw`cursor-pointer`};
      }

      &.h {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .data-table-header {
    ${tw`text-black-2  border-b border-gray-9`};
  }
  .data-table-row {
    ${tw`text-black-2  border-b border-gray-9`};
  }
`;

export default Assignment;

import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Collapse, Progress } from 'antd';
import { AngleDown, AngleUp, Clock } from 'src/icons';
import { GraphContainer } from 'src/components/quiz/Quiz';
import Chart from 'src/icons/Chart';
import Chat from 'src/icons/Chat';
type LiveNowProps = {
  onClose: MouseEventHandler<HTMLSpanElement>;
  sendSocketAction: () => void;
};

const LiveNow: React.FC<LiveNowProps> = ({ onClose }) => {
  return (
    <LiveNowContainer>
      <div className="time-action mb-6">
        <span className="clock">
          <Clock />
        </span>
        <span className="timeleft">
          Time Left: <span className="time">08:30</span>
        </span>
        <span className="end-action" onClick={onClose}>
          END NOW
        </span>
      </div>

      <CollapseWrapper>
        <Collapse
          defaultActiveKey={['']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => {
            return (
              <span
                role="img"
                aria-label="right"
                className="anticon anticon-right ant-collapse-arrow"
              >
                {isActive ? <AngleUp /> : <AngleDown />}
              </span>
            );
          }}
          className="--collapse"
        >
          <Collapse.Panel
            key="1"
            header={
              <div className="collapse-header">
                <h4>Question 1</h4>
                Which expression is a factor of g(x) = x<sup>2</sup> - 16x + 63?
              </div>
            }
          >
            <GraphContainer>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">A</p>
                  <p>
                    24/50<span className="parcent">(48%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#494FB1" percent={48} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">B</p>
                  <p>
                    40/50<span className="parcent">(30%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={30} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">C</p>
                  <p>
                    40/50<span className="parcent">(15%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={15} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">D</p>
                  <p>
                    30/50<span className="parcent">(10%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={10} showInfo={false} />
              </div>
            </GraphContainer>
          </Collapse.Panel>
        </Collapse>
      </CollapseWrapper>

      <CollapseWrapper>
        <Collapse
          defaultActiveKey={['']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => {
            return (
              <span
                role="img"
                aria-label="right"
                className="anticon anticon-right ant-collapse-arrow"
              >
                {isActive ? <AngleUp /> : <AngleDown />}
              </span>
            );
          }}
          className="--collapse"
        >
          <Collapse.Panel
            key="2"
            header={
              <div className="collapse-header">
                <h4>Question 2</h4>
                Which expression is a factor of g(x) = x<sup>5</sup> - 1x + 1?
              </div>
            }
          >
            <GraphContainer>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">A</p>
                  <p>
                    24/50<span className="parcent">(48%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#494FB1" percent={48} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">B</p>
                  <p>
                    40/50<span className="parcent">(30%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={30} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">C</p>
                  <p>
                    40/50<span className="parcent">(15%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={15} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">D</p>
                  <p>
                    30/50<span className="parcent">(10%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={10} showInfo={false} />
              </div>
            </GraphContainer>
          </Collapse.Panel>
        </Collapse>
      </CollapseWrapper>

      <CollapseWrapper>
        <Collapse
          defaultActiveKey={['']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => {
            return (
              <span
                role="img"
                aria-label="right"
                className="anticon anticon-right ant-collapse-arrow"
              >
                {isActive ? <AngleUp /> : <AngleDown />}
              </span>
            );
          }}
          className="--collapse"
        >
          <Collapse.Panel
            key="3"
            header={
              <div className="collapse-header">
                <h4>Question 3</h4>
                Which expression is a factor of g(x) = x<sup>3</sup> - 17x + 4?
              </div>
            }
          >
            <GraphContainer>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">A</p>
                  <p>
                    24/50<span className="parcent">(48%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#494FB1" percent={48} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">B</p>
                  <p>
                    40/50<span className="parcent">(30%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={30} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">C</p>
                  <p>
                    40/50<span className="parcent">(15%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={15} showInfo={false} />
              </div>
              <div className="graph-item mb-4">
                <div className="info">
                  <p className="index">D</p>
                  <p>
                    30/50<span className="parcent">(10%)</span>
                  </p>
                </div>
                <Progress strokeWidth={4} strokeColor="#36A593" percent={10} showInfo={false} />
              </div>
            </GraphContainer>
          </Collapse.Panel>
        </Collapse>
      </CollapseWrapper>
      <InclassActivities className="mb-4">
        <h5 className="title mb-10">IN CLASS STUDENTS ACTIVITIES</h5>
        <div className="activity">
          <div className="user">
            <div className="avatar">
              <img src="/img/dummy/avatars/taylorProfile.jpg" alt="avatar" />
            </div>
            <h4 className="name">
              Sophia Lorenz <span className="time">5 mins ago</span>
              <p>Completed his homework</p>
            </h4>
          </div>
          <ActivityLog>
            <div className="indicators">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="message">Slightly engaged (last 20 minutes)</p>
          </ActivityLog>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar">
              <img src="/img/dummy/avatars/dwight.jpeg" alt="avatar" />
            </div>
            <h4 className="name">
              Dwight Schrute
              <p>Completed his homework</p>
            </h4>
          </div>
          <ActivityLog>
            <div className="indicators">
              <span className="success"></span>
              <span className="success"></span>
              <span className="success"></span>
              <span className="success"></span>
              <span></span>
            </div>
            <p className="message">Slightly engaged (last 20 minutes)</p>
          </ActivityLog>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar">
              <img src="/img/dummy/avatars/JulesWinnfield.jpg" alt="avatar" />
            </div>
            <h4 className="name">
              Jules Winnfield
              <p>Completed his homework</p>
            </h4>
          </div>
          <div className="flex items-center text-xl cursor-pointer">
            <span>
              <Chart />
            </span>
            <span className="ml-3">
              <Chat />
            </span>
          </div>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar">
              <img src="/img/dummy/avatars/lukeSkywalker.jpeg" alt="avatar" />
            </div>
            <h4 className="name">
              Luke Skywalker
              <p>Completed his homework</p>
            </h4>
          </div>
          <div className="flex items-center text-xl cursor-pointer">
            <span>
              <Chart />
            </span>
            <span className="ml-3">
              <Chat />
            </span>
          </div>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar not-active">
              <img src="/img/dummy/avatars/IndianaJones.jpg" alt="avatar" />
            </div>
            <h4 className="name">
              Indiana Jones
              <p>Completed his homework</p>
            </h4>
          </div>
          <div className="flex items-center text-xl cursor-pointer">
            <span>
              <Chart />
            </span>
            <span className="ml-3">
              <Chat />
            </span>
          </div>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar active">
              <img src="/img/dummy/avatars/Bruce_Wayne.jpg" alt="avatar" />
            </div>
            <h4 className="name">
              Bruce Wayne
              <p>Completed his homework</p>
            </h4>
          </div>
          <div className="flex items-center text-xl cursor-pointer">
            <span>
              <Chart />
            </span>
            <span className="ml-3">
              <Chat />
            </span>
          </div>
        </div>
        <div className="activity">
          <div className="user">
            <div className="avatar active">
              <img src="/img/dummy/avatars/harryPotter.jpg" alt="avatar" />
            </div>
            <h4 className="name">
              Harry Potter
              <p>Completed his homework</p>
            </h4>
          </div>
          <div className="flex items-center text-xl cursor-pointer">
            <span>
              <Chart />
            </span>
            <span className="ml-3">
              <Chat />
            </span>
          </div>
        </div>
      </InclassActivities>
    </LiveNowContainer>
  );
};

const LiveNowContainer = styled.div`
  .time-action {
    height: 60px;
    ${tw`bg-error bg-opacity-5 rounded-lg flex items-center px-4`};
    .clock {
      width: 32px;
      height: 32px;
      ${tw`bg-white rounded-full grid place-items-center text-error mr-3`};
    }
    .timeleft {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      ${tw`text-black mr-auto`};
      .time {
        font-weight: bold;
      }
    }
    .end-action {
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      ${tw`text-error cursor-pointer`};
    }
  }
`;

const CollapseWrapper = styled.div`
  .--collapse {
    border: 0.85956px solid rgba(0, 0, 0, 0.06);
    box-shadow: none;
    overflow: initial;
    ${tw`bg-white rounded-lg mb-4`};
    .ant-collapse-item {
      border-bottom: none !important;
    }
    .ant-collapse-header,
    .ant-collapse-content {
      border-bottom: none !important;
      border-top: none !important;
      border-radius: 0 0 8px 8px !important;
    }
    .ant-collapse-arrow {
      font-size: 18px !important;
    }
    .ant-collapse-item-active {
      .ant-collapse-arrow {
        ${tw`text-primary`};
      }
    }
    .collapse-header {
      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        ${tw`text-primary`};
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .ant-collapse-content-box {
      ${tw`px-9`};
    }
  }
`;

const ActivityLog = styled.div`
  .indicators {
    ${tw`grid grid-cols-5`};
    gap: 3px;
    span {
      width: 31px;
      height: 4px;
      border-radius: 20px;
      ${tw`bg-red-1`};
      &.success {
        ${tw`bg-green-100`};
      }
    }
  }
  .message {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    margin-top: 3px;
    ${tw`text-gray-10`};
  }
`;
const InclassActivities = styled.div`
  font-style: normal;
  ${tw`text-black-1 mb-16`};
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    ${tw`text-gray-10`};
  }
  .activity {
    ${tw`flex items-center justify-between mb-4`};
    .user {
      ${tw`flex items-center relative`};
      &:before {
        content: '';
        top: 4px;
        left: 15px;
        position: absolute;
        width: 10px;
        height: 150%;
        display: flex;
        border-left: 2px dashed rgba(0, 82, 204, 0.1);
        border-radius: 2px;
      }
      .avatar {
        width: 32px;
        height: 32px;
        ${tw`rounded-full mr-3`};
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }

        &.active {
          ${tw`border-2 border-green-100`};
        }
        &.not-active {
          ${tw`border-2 border-red-1`};
        }
      }
      .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        span.time,
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          ${tw`text-gray-10`};
        }
      }
    }
    &:last-child {
      .user {
        &:before {
          border-left: 0;
        }
      }
    }
  }
`;
export default LiveNow;

export { CollapseWrapper };

import { Tabs as AntTabs } from 'antd';
import { TabPaneProps, TabsProps } from 'antd/lib/tabs';
import React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

const { TabPane: AntTabPane } = AntTabs;

export default function Tabs(props: TabsProps) {
  return <StyledTabs {...props} />;
}

export function TabPane(props: TabPaneProps) {
  return <AntTabPane {...props} />;
}

var StyledTabs = styled(AntTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: ${theme`colors.black`};
    line-height: 36px;
  }
  .ant-tabs-ink-bar {
    background-color: ${theme`colors.primary`};
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import tw from 'twin.macro';
import AppBar from 'src/components/AppBar';
import Bar from 'src/icons/Bar';
import ClassSidebar from 'src/components/classPage/classSidebar';
import getWindowSize from 'src/utils/getWindowSize';
import { AttendanceModal } from 'src/components/classPage/classSidebar/controller/attendance';
import Tabs from 'src/components/primitives/Tabs';
import { TabPane } from 'src/components/primitives/Tabs';
import Preview from 'src/components/preview';
import Files from 'src/components/classPage/classSidebar/Files';
import Assignment from 'src/components/classPage/classSidebar/Assignment';
import Attendance from 'src/components/classPage/classSidebar/controller/attendance';

const Notes = dynamic(() => import('src/components/classPage/classSidebar/Notes'), { ssr: false });
const StreamView = dynamic(() => import('src/components/stream/StreamView'), { ssr: false });
const PrivateChat = dynamic(() => import('src/components/privateChat'), { ssr: false });

const minScreenSize = 1024;

let initial = true;

const OldLessonPage = () => {
  // const router = useRouter();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [fullSize, setFullSize] = useState(false);
  // const { classId, lessonId } = router.query;
  const screenSize = getWindowSize();
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState('controller');

  useEffect(() => {
    if (initial) {
      initial = false;
      const open = window.innerWidth >= minScreenSize;
      setOpenSidebar(open);
      setFullSize(!open);
    } else {
      setFullSize((screenSize[0] || window.innerWidth) >= minScreenSize && !openSidebar);
    }
  }, [screenSize, openSidebar]);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleShowAttendanceModal = () => {
    setShowAttendanceModal(true);
    setDefaultTab('');
  };

  const handleAttendanceModalConfirm = (checked: boolean) => {
    setDefaultTab('attendance');
    setShowAttendanceModal(false);
    setOpenSidebar(true);
  };

  return (
    <Wrapper>
      <AppBar
        className="lesson-bar app-bar-light"
        light={true}
        rightContent={
          !openSidebar ? (
            <span onClick={handleSidebarOpen} className="text-2xl text-black-2 cursor-pointer">
              <Bar />
            </span>
          ) : (
            <span></span>
          )
        }
      />

      <Container>
        <div className={`class-content ${fullSize ? 'full-screen' : ''}`}>
          <StreamView
            className={openSidebar ? 'sidebar-open' : ''}
            showAttendanceModal={handleShowAttendanceModal}
          />
          <div className="class-details-wrapper mt-5" id="detail-container">
            <h2 className="lesson-title">1a Introduction to week 1 (3 Questions)</h2>
            <div className="creator">
              By <strong> Stephanie Fernandez,</strong> created 4 days ago
            </div>
            <Tabs
              tabBarStyle={{
                // paddingLeft: getTabPadding(),
                position: 'relative',
                background: '#fafafa',
              }}
            >
              <TabPane tab="Preview" key="1" className="content">
                <Preview />
              </TabPane>
              <TabPane tab="Announcements" key="2" className="content">
                {/* <h1>Syllabus</h1> */}
              </TabPane>
              <TabPane tab="Assignment" key="3" className="content">
                <Assignment />
              </TabPane>
              <TabPane tab="Attendance" key="5" className="content">
                <Attendance />
              </TabPane>
              <TabPane tab="Resources" key="6" className="content">
                <Files />
              </TabPane>
              <TabPane tab="Note" key="7" className="content">
                <Notes />
              </TabPane>
            </Tabs>
          </div>
        </div>

        <div className="tab-content">
          <ClassSidebar
            defaultTab={defaultTab}
            showAttendanceModal={handleShowAttendanceModal}
            open={openSidebar}
            onClose={handleCloseSidebar}
          />
        </div>
      </Container>

      <AttendanceModal onConfirm={handleAttendanceModalConfirm} open={showAttendanceModal} />
      <PrivateChat />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .lesson-bar {
    height: 60px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.1) !important;
  }
`;

const Container = styled.div`
  ${tw`px-4`};
  .class-content {
    ${tw`mt-20 2xl:w-8/12 xl:w-6/12 w-full transition-all delay-75`};
    .lesson-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      ${tw`text-black-2`};
    }
    .creator {
      ${tw`mb-4`};
    }
    @media (min-width: 1024px) {
      width: calc(100% - 545px);
    }
    &.full-screen {
      ${tw`2xl:w-9/12 xl:w-9/12 lg:w-full xl:mx-auto 2xl:mx-auto`};
    }
    .content {
      max-width: 700px;
      ${tw`mt-12 mx-auto pb-12`};
    }
  }
`;

export default OldLessonPage;

import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Collapse } from 'antd';
import Select from 'react-select';
import { OverLay, SlideContainer } from '../gameList/GameList';
import { CollapseWrapper } from '../livenow/LiveNow';
import { Close, CornerUpLeft, AngleDown, AngleUp, PlusCircle } from 'src/icons';
import {
  SelectWrapper,
  SingleValue,
  Control,
  DropdownIndicator,
} from 'src/components/publishCard/PublishCard';

type PublishQuizTypes = {
  open: boolean;
  onClose: () => void;
};

const PublishQuiz: React.FC<PublishQuizTypes> = ({ open, onClose }) => {
  return (
    <PublishQuizContainer>
      {open ? <OverLay /> : null}
      <SlideContainer className={open ? 'open' : ''}>
        <div className="slide-heading mb-4">
          <div className="action-bar mb-6">
            <button className="back" onClick={onClose}>
              <CornerUpLeft className="mr-1" />
              Back to Controller
            </button>
            <span onClick={onClose}>
              <Close />
            </span>
          </div>
          <h2 className="title">Publish Quiz</h2>
        </div>

        <CollapseWrapper>
          <Collapse
            defaultActiveKey={['']}
            expandIconPosition="right"
            accordion={true}
            expandIcon={({ isActive }) => {
              return (
                <span
                  role="img"
                  aria-label="right"
                  className="anticon anticon-right ant-collapse-arrow"
                >
                  {isActive ? <AngleUp /> : <AngleDown />}
                </span>
              );
            }}
            className="--collapse"
          >
            <Collapse.Panel
              key="1"
              header={
                <div className="collapse-header flex items-center justify-between">
                  <div className="left">
                    <h4>Maths quiz</h4>
                    <p>5 Questions</p>
                  </div>
                  <div className="right" onClick={(e) => e.stopPropagation()}>
                    <SelectWrapper>
                      <Select
                        options={[
                          { value: 'published', label: 'Published' },
                          { value: 'unpublished', label: 'Unpublished' },
                        ]}
                        classNamePrefix="custom"
                        components={{
                          SingleValue,
                          DropdownIndicator,
                          Control,
                        }}
                      />
                    </SelectWrapper>
                  </div>
                </div>
              }
            >
              this is a boddy of the collpase
            </Collapse.Panel>
          </Collapse>
        </CollapseWrapper>

        <button className="create-now mt-6">
          Create New <PlusCircle className="ml-1" />
        </button>
      </SlideContainer>
    </PublishQuizContainer>
  );
};

const PublishQuizContainer = styled.div`
  .ant-collapse-arrow {
    top: 20px !important;
  }
  .collapse-header {
    .right {
      width: 154px;
    }
    .left {
      p {
        font-weight: 500;
      }
    }
  }

  .create-now {
    border: 0.85956px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    height: 70px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    ${tw`flex items-center justify-center bg-white rounded-lg focus:outline-none w-full text-primary`};
  }
`;
export default PublishQuiz;

import React from 'react';

const DropDown = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58422 6.00009C9.47534 6.00009 9.92141 4.9225 9.29101 4.29267L5.70199 0.706881C5.31129 0.316533 4.67813 0.316818 4.28778 0.707519L0.705224 4.2933C0.0756964 4.9234 0.521961 6.00009 1.41265 6.00009H8.58422ZM8.58422 12.0001C9.47534 12.0001 9.92141 13.0777 9.29101 13.7075L5.70199 17.2933C5.31129 17.6837 4.67813 17.6834 4.28778 17.2927L0.705224 13.7069C0.0756964 13.0768 0.521961 12.0001 1.41265 12.0001H8.58422Z"
        fill="#8F92A1"
      />
    </svg>
  );
};

export default DropDown;

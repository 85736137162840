import React from 'react';
export default function Delete({ color = '#EB0000', width = '1em', height = '1em' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41344 10.0959C6.35759 9.36988 6.93162 8.75 7.65975 8.75H16.3402C17.0684 8.75 17.6424 9.36988 17.5866 10.0959L17.0363 17.2493C16.9061 18.9425 15.4941 20.25 13.7959 20.25H10.2041C8.50588 20.25 7.09395 18.9425 6.9637 17.2493L6.41344 10.0959Z"
        stroke="#EB0000"
        strokeWidth="1.5"
      />
      <path
        d="M13.5 13L10.5 16M10.5 13L13.5 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="6.25"
        y1="6.25"
        x2="17.75"
        y2="6.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 5C9.5 4.44772 9.94772 4 10.5 4H13.5C14.0523 4 14.5 4.44772 14.5 5V6H9.5V5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}

import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import tw from 'twin.macro';
import Notification from 'src/icons/Notification';
import Close from 'src/icons/Close';
// import Activities from './Activities';
import Controller from './controller';
import Attendance from './controller/attendance';

const Chat = dynamic(() => import('./Chat'), { ssr: false });
const Students = dynamic(() => import('./students'), { ssr: false });

type OpenTabType =
  | 'activities'
  | 'assignment'
  | 'chat'
  | 'files'
  | 'notes'
  | 'controller'
  | 'attendance'
  | 'students';

const tabsName: OpenTabType[] = ['controller', 'chat', 'attendance', 'students', 'activities'];

type CSProps = {
  open?: boolean;
  onClose?: () => void;
  showAttendanceModal: () => void;
  sendSocketAction?: () => void;
  defaultTab: OpenTabType | string;
};

const ClassSidebar: React.FC<CSProps> = ({
  open = true,
  onClose,
  showAttendanceModal,
  sendSocketAction,
  defaultTab = '',
}) => {
  const [openTab, setOpenTab] = useState<OpenTabType | string>('activities');

  useEffect(() => {
    if (defaultTab) setOpenTab(defaultTab);
  }, [defaultTab]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleTabChange = (e: React.MouseEvent<HTMLAnchorElement>, tab: OpenTabType) => {
    e.preventDefault();
    setOpenTab(tab);
  };

  const renderTabs = (tab: OpenTabType | string, isMenuOpen = true) => {
    switch (tab) {
      // case tabsName[0]:
      //   return <Activities isMenuOpen={isMenuOpen} />;
      case tabsName[0]:
        return (
          <Controller
            showAttendanceModal={showAttendanceModal}
            sendSocketAction={sendSocketAction}
          />
        );
      // return <Assignment />;
      case tabsName[1]:
        return <Chat />;
      case tabsName[2]:
        // return <Files />;
        return <Attendance />;
      case tabsName[3]:
        // return <Notes />;
        return <Students sendSocketAction={sendSocketAction} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SidebarWrapper className={open ? '--open' : '--close'}>
        <TabMenuContainer className={`mobile-mode ${open ? '--open' : '--close'}`}>
          <ul>
            {tabsName.map((name) => (
              <li key={name}>
                <a
                  href="#"
                  onClick={(e) => handleTabChange(e, name)}
                  className={openTab === name ? 'active' : ''}
                >
                  {name.charAt(0).toUpperCase() + name.substr(1)}
                </a>
              </li>
            ))}
          </ul>
          <div className="action">
            <span className="notification xs:mr-2">
              <Notification />
              <div className="item">17</div>
            </span>
            <span onClick={handleClose}>
              <Close />
            </span>
          </div>
          <button className="take-attendance" onClick={showAttendanceModal}>
            Take Attendance
          </button>
        </TabMenuContainer>
        <div className="content">{renderTabs(openTab)}</div>
        {/* <div className="content">{renderTabs(openTab, open)}</div> */}
      </SidebarWrapper>
    </>
  );
};

const SidebarWrapper = styled.div`
    height: 100%;
    width: 540px;
    position: fixed;
    top: 0px;
    transition: all 300ms linear;
    z-index: 10000;
    ${tw`bg-white h-full pb-4`};
    @media(max-width:500px) {
        width: 100%;
    }
    &.--open {
        right: 0;
    }
    &.--close {
        right: -100%;
    }
    .content {
        top: 60px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
        &::-ms-overflow-style: none; 
        scrollbar-width: none;
        ${tw`p-8 xs:p-3`};
    }
`;

const TabMenuContainer = styled.div`
  ${tw`flex items-center justify-between relative`};
  height: 60px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  ul {
    ${tw`flex xl:mr-8`};
    li {
      a {
        ${tw`py-5 xl:mx-4 lg:ml-4 lg:mr-3 md:mx-4 mx-2 font-medium text-gray-6 hover:border-b-2 border-primary hover:text-primary hover:font-bold transition-all delay-75 rounded-b-sm`};
        @media (max-width: 1024px) {
          margin-right: 0;
        }
        &.active {
          ${tw`border-b-2 border-primary text-primary font-bold`};
        }
        &:first-child {
          ${tw`xl:ml-3`};
        }
      }
    }
  }
  .action {
    font-size: 20px;
    ${tw`flex items-center cursor-pointer text-gray-6 pr-2`};
    .notification {
      width: 32px;
      height: 32px;
      font-size: 12px;
      ${tw`rounded-full bg-white grid place-items-center border border-gray-7 relative text-primary xl:mr-6 lg:mr-6 md:mr-3 xs:mr-4`};
      .item {
        width: 16px;
        height: 16px;
        top: 0;
        right: -8px;
        font-size: 8px;
        line-height: 8px;
        ${tw`absolute bg-red-500 rounded-full text-white grid place-items-center font-semibold`};
      }
    }
  }

  .take-attendance {
    width: 143px;
    height: 38px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    ${tw`
      fixed 
      top-2.5 
      bg-green-100
      rounded-lg 
      text-white 
      flex 
      items-center 
      justify-center 
      border-none
      focus:outline-none
    `};
  }
  &.--close {
    .take-attendance {
      right: 8%;
    }
  }
  &.--open {
    .take-attendance {
      right: 30%;
    }
  }

  @media (max-width: 1440px) {
    &.--open {
      .take-attendance {
        right: 40%;
      }
    }
  }
  @media (max-width: 1324px) {
    &.--open {
      .take-attendance {
        right: 42%;
      }
    }
  }
  @media (max-width: 1324px) {
    &.--open {
      .take-attendance {
        right: 51%;
      }
    }
    &.--close {
      .take-attendance {
        right: 10%;
      }
    }
  }
  @media (max-width: 1024px) {
    &.--open {
      .take-attendance {
        right: 53%;
      }
    }
    &.--close {
      .take-attendance {
        right: 12%;
      }
    }
  }

  @media (max-width: 860px) {
    &.--open {
      .take-attendance {
        display: none;
      }
    }
  }
`;

export default ClassSidebar;

import React from 'react';

const Pdf = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75 0C3.0625 0 2.5 0.5625 2.5 1.25V18.75C2.5 19.4375 3.0625 20 3.75 20H16.25C16.9375 20 17.5 19.4375 17.5 18.75V5L12.5 0H3.75Z"
        fill="#E2E5E7"
      />
      <path d="M13.75 5H17.5L12.5 0V3.75C12.5 4.4375 13.0625 5 13.75 5Z" fill="#B0B7BD" />
      <path d="M17.5 8.75L13.75 5H17.5V8.75Z" fill="#CAD1D8" />
      <path
        d="M15 16.25C15 16.5938 14.7188 16.875 14.375 16.875H0.625C0.28125 16.875 0 16.5938 0 16.25V10C0 9.65625 0.28125 9.375 0.625 9.375H14.375C14.7188 9.375 15 9.65625 15 10V16.25Z"
        fill="#F15642"
      />
      <path
        d="M2.86914 11.8418C2.86914 11.6768 2.99914 11.4968 3.20852 11.4968H4.36289C5.01289 11.4968 5.59789 11.9318 5.59789 12.7656C5.59789 13.5556 5.01289 13.9956 4.36289 13.9956H3.52852V14.6556C3.52852 14.8756 3.38914 15 3.20914 15C3.04414 15 2.86977 14.8756 2.86977 14.6556V11.8418H2.86914ZM3.52852 12.1262V13.3712H4.36289C4.69789 13.3712 4.96289 13.0756 4.96289 12.7656C4.96289 12.4162 4.69789 12.1262 4.36289 12.1262H3.52852Z"
        fill="white"
      />
      <path
        d="M6.23486 11.8418C6.23486 11.6768 6.36486 11.4968 6.57486 11.4968H7.72924C8.37924 11.4968 8.96424 11.9318 8.96424 12.7656C8.96424 13.5556 8.37924 13.9956 7.72924 13.9956H6.89486V14.6556C6.89486 14.8756 6.75486 15 6.57486 15C6.40986 15 6.23486 14.8756 6.23486 14.6556V11.8418ZM6.89424 12.1262V13.3712H7.72861C8.06361 13.3712 8.32861 13.0756 8.32861 12.7656C8.32861 12.4162 8.06361 12.1262 7.72861 12.1262H6.89424Z"
        fill="white"
      />
      <path
        d="M10.5345 12.1671H9.66072C9.22572 12.1671 9.22572 11.5277 9.66072 11.5277H12.0238C12.4688 11.5277 12.4688 12.1671 12.0238 12.1671H11.1945V14.6908C11.1945 15.1252 10.5345 15.1358 10.5345 14.6908V12.1671Z"
        fill="white"
      />
      <path
        d="M14.375 16.875H2.5V17.5H14.375C14.7188 17.5 15 17.2188 15 16.875V16.25C15 16.5938 14.7188 16.875 14.375 16.875Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};

export default Pdf;

import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Progress } from 'antd';
import CheckCircle from 'src/icons/CheckCircle';
import Graph from 'src/icons/Graph';
import Chart from 'src/icons/Chart';
import MoreVertical from 'src/icons/MoreVertical';

type QProps = {
  className?: string;
};

const Quiz: React.FC<QProps> = ({ className = '' }) => {
  const [showGrap, setShowGraph] = useState(false);

  return (
    <QuizItem className={className}>
      <div className="quiz--header">
        <h5 className="title">QUIZ</h5>
        <div className="action cursor-pointer">
          <span
            className={`mr-3 ${showGrap === true ? 'text-primary' : ''}`}
            onClick={() => setShowGraph(true)}
          >
            {showGrap ? <Chart /> : <Graph />}
          </span>
          <span onClick={() => setShowGraph(false)}>
            <MoreVertical />
          </span>
        </div>
      </div>
      <div className="quiz--body">
        <h5 className="title my-4">
          Which expression is a factor of g(x) = x<sup>2</sup> - 16x + 63?
        </h5>
        {!showGrap ? (
          <div className="quiz--list my-6">
            <div className="quiz selected">
              <span className="index">A</span>x - 7
              <span className="check">
                <CheckCircle />
              </span>
            </div>
            <div className="quiz">
              <span className="index">B</span>x - 21
              <span className="check">
                <CheckCircle />
              </span>
            </div>
            <div className="quiz selected">
              <span className="index">C</span>x + 7
              <span className="check">
                <CheckCircle />
              </span>
            </div>
            <div className="quiz">
              <span className="index">D</span>x - 3
              <span className="check">
                <CheckCircle />
              </span>
            </div>
          </div>
        ) : (
          <GraphContainer>
            <div className="graph-item mb-4">
              <div className="info">
                <p className="index">A</p>
                <p>
                  24/50<span className="parcent">(48%)</span>
                </p>
              </div>
              <Progress strokeWidth={4} strokeColor="#494FB1" percent={48} showInfo={false} />
            </div>
            <div className="graph-item mb-4">
              <div className="info">
                <p className="index">B</p>
                <p>
                  40/50<span className="parcent">(80%)</span>
                </p>
              </div>
              <Progress strokeWidth={4} strokeColor="#36A593" percent={80} showInfo={false} />
            </div>

            <div className="class-work-info grid grid-cols-2 xl:w-4/6 xl:gap-2 my-5">
              <p>
                Average Time: <span>20 min</span>
              </p>
              <p>
                Average Answered: <span>10/50 Questions</span>
              </p>
            </div>
            <button className="more-analytics bg-gray-10 bg-opacity-10 rounded-2xl focus:outline-none flex items-center h-10 px-4 my-8">
              <Graph className="mr-2" /> See More Analytics
            </button>
          </GraphContainer>
        )}
        <div className="sync">
          <img src="/img/power-school.png" alt="power-school" />
          <span>Synced with Powerschool on March 17,2021</span>
        </div>
      </div>
    </QuizItem>
  );
};

const QuizItem = styled.div`
  .title {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    ${tw`text-black-1`};
  }
  .quiz--header {
    ${tw`flex justify-between items-center`};
    .action {
      font-size: 20px;
      ${tw`flex items-center text-gray-8`};
    }
    .title {
      ${tw`text-primary`};
    }
  }

  .quiz--body {
    .quiz--list {
      ${tw`px-4`};
      .quiz {
        font-family: 'Jost', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 50px;
        ${tw`flex items-center text-black-1 rounded-lg px-4 cursor-pointer`};
        .index {
          font-weight: 500;
          font-size: 16px;
          ${tw`mr-4 text-black`};
        }
        .check {
          font-size: 20px;
          ${tw`ml-auto hidden`};
        }
        &.selected {
          background: rgba(54, 165, 147, 0.1);
          .check {
            color: rgba(54, 165, 147);
            ${tw`ml-auto block`};
          }
        }
      }
    }

    .sync {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      ${tw`flex items-center text-orange-1 mb-6`};
      img {
        width: 17px;
        height: 17px;
        object-fit: cover;
        ${tw`mr-1`};
      }
    }
  }
`;

const GraphContainer = styled.div`
  font-family: 'Jost', sans-serif;
  .graph-item {
    .info {
      font-style: normal;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }
      .index {
        font-weight: 800;
      }
      ${tw`flex items-center justify-between text-black-2`};
      .parcent {
        ${tw`text-gray-1`};
      }
    }
  }
`;

export default Quiz;
export { GraphContainer };

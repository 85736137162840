import React from 'react';

const Star = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2994 11.1273C10.5362 11.706 11.624 11.0046 11.3874 9.77712L10.9627 7.57313L12.7619 6.01226C13.7625 5.14417 13.3491 4.00671 11.9643 3.82762L9.47791 3.50606L8.37145 1.51072C7.75166 0.393032 6.40594 0.38101 5.78499 1.50081L4.67303 3.50606L2.1866 3.82762C0.803763 4.00646 0.386996 5.14295 1.38904 6.01226L3.18824 7.57313L2.7635 9.77712C2.52729 11.0029 3.61295 11.7068 4.85155 11.1273L7.07547 10.0867L9.2994 11.1273Z"
        fill="#FF991F"
      />
    </svg>
  );
};

export default Star;

import React, { useState, useCallback } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import ArrowRight from 'src/icons/ArrowRight';
import GameList from './gameList';
import LiveNow from './livenow';
import PublishQuiz from './publishQuiz';

type ControllerPropsType = {
  showAttendanceModal?: () => void;
  sendSocketAction?: () => void;
};

const Controller: React.FC<ControllerPropsType> = ({ showAttendanceModal, sendSocketAction }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleClose = (option: string, type: string, data?: any) => {
    setSelectedOption('');
    // @ts-ignore
    sendSocketAction(type, { type: option });
  };

  // const handleEndClick = (option: string, type: String, data?: any) => {
  //   setSelectedOption('');
  // };

  const renderOption = useCallback(() => {
    switch (selectedOption) {
      case 'livenow':
        // @ts-ignore
        return <LiveNow onClose={handleClose} sendSocketAction={sendSocketAction} />;
      default:
        return null;
    }
  }, [selectedOption]);

  const handleStartClick = (option: string, type: string, data?: any) => {
    setSelectedOption(option);
    // @ts-ignore
    sendSocketAction(type, { type: option });
  };

  return (
    <>
      <ControllerContainer>
        <div className="publish-action">
          <button
            className={`fat-btn mr-3 ${selectedOption === '' ? 'primary' : ''}`}
            onClick={() => setSelectedOption('')}
          >
            {selectedOption === 'livenow' ? 'Add Task' : 'Push New'}
          </button>
          <button
            className={`fat-btn ${selectedOption === 'livenow' ? 'primary' : ''}`}
            onClick={() => setSelectedOption('livenow')}
          >
            Live Now
          </button>
        </div>
        {selectedOption === '' || selectedOption === 'attendance' ? (
          <>
            <div className="summary">
              <h3>Teacher’s Controller</h3>
              <p>
                You can assign any activity below to students while they are in class. You will be
                able to see live data on students performance and progress.{' '}
              </p>
            </div>
            <div className="actionList">
              <div className="actionItem">
                <div className="info">
                  <h4>Take Attendance</h4>
                  <p>Simplified options to take attendance in class</p>
                </div>
                <div className="action">
                  <button
                    className="fat-btn primary start"
                    onClick={() => showAttendanceModal && showAttendanceModal()}
                  >
                    Start{' '}
                    <span className="ml-2.5">
                      <ArrowRight />
                    </span>
                  </button>
                </div>
              </div>
              <div className="actionItem">
                <img src="/img/dummy/game-cover-1.png" className="cover" alt="game cover" />
                <div className="info">
                  <h4>Game</h4>
                  <p>Push game activities live to students</p>
                </div>
                <div className="action">
                  <button
                    className="fat-btn primary start"
                    onClick={() => handleStartClick('game', 'startLiveActivity')}
                  >
                    Start{' '}
                    <span className="ml-2.5">
                      <ArrowRight />
                    </span>
                  </button>
                </div>
              </div>
              <div className="actionItem">
                <img src="/img/dummy/game-cover-7.png" className="cover" alt="game cover" />
                <div className="info">
                  <h4>Curriki</h4>
                  <p>Push a curriki activity</p>
                </div>
                <div className="action">
                  <button
                    className="fat-btn primary start"
                    onClick={() => handleStartClick('curriki', 'startLiveActivity')}
                  >
                    Start{' '}
                    <span className="ml-2.5">
                      <ArrowRight />
                    </span>
                  </button>
                </div>
              </div>
              <div className="actionItem">
                <img src="/img/dummy/game-cover-8.png" className="cover" alt="game cover" />
                <div className="info">
                  <h4>Actively Learn</h4>
                  <p>Module learning, smart maths, interactive</p>
                </div>
                <div className="action">
                  <button className="fat-btn primary start">
                    Start{' '}
                    <span className="ml-2.5">
                      <ArrowRight />
                    </span>
                  </button>
                </div>
              </div>
              <div className="actionItem">
                <img src="/img/dummy/game-cover-9.png" className="cover" alt="game cover" />
                <div className="info">
                  <h4>Quiz</h4>
                  <p>Create and push quiz live to students</p>
                </div>
                <div className="action">
                  <button
                    className="fat-btn primary start"
                    onClick={() => setSelectedOption('quiz')}
                  >
                    Start{' '}
                    <span className="ml-2.5">
                      <ArrowRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          renderOption()
        )}

        <GameList
          open={selectedOption === 'game'}
          // @ts-ignore
          onClose={handleClose}
        />

        <PublishQuiz
          open={selectedOption === 'quiz'}
          // @ts-ignore
          onClose={handleClose}
        />
      </ControllerContainer>
    </>
  );
};

const ControllerContainer = styled.div`
  .fat-btn {
    height: 40px;
    width: 85px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    ${tw`rounded-xl bg-gray-10 bg-opacity-10 border-none inline-flex items-center justify-center text-black-2 focus:outline-none`};
    &.primary {
      ${tw`bg-primary text-white`};
    }
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    ${tw`text-black`};
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    ${tw`text-gray`};
  }

  .publish-action {
    ${tw`mb-4`};
  }

  .summary {
    ${tw`mb-8`};
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      ${tw`text-black mb-3`};
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      ${tw`text-gray`};
    }
  }

  .actionList {
    .actionItem {
      border: 0.85956px solid;
      box-sizing: border-box;
      ${tw`bg-white rounded-lg p-4 border-black border-opacity-5 flex mb-4`};
      .cover {
        width: 72px;
        height: 55px;
        object-fit: cover;
        ${tw`rounded-lg mr-4`};
      }

      .action {
        ${tw`ml-auto self-center`};
        .start {
          width: 79px;
          height: 36px;
          ${tw`rounded-lg`};
        }
      }
    }
  }
`;
export default Controller;

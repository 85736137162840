import React from 'react';

const Doc = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75 0C3.0625 0 2.5 0.5625 2.5 1.25V18.75C2.5 19.4375 3.0625 20 3.75 20H16.25C16.9375 20 17.5 19.4375 17.5 18.75V5L12.5 0H3.75Z"
        fill="#E2E5E7"
      />
      <path d="M13.75 5H17.5L12.5 0V3.75C12.5 4.4375 13.0625 5 13.75 5Z" fill="#B0B7BD" />
      <path d="M17.5 8.75L13.75 5H17.5V8.75Z" fill="#CAD1D8" />
      <path
        d="M15 16.25C15 16.5938 14.7188 16.875 14.375 16.875H0.625C0.28125 16.875 0 16.5938 0 16.25V10C0 9.65625 0.28125 9.375 0.625 9.375H14.375C14.7188 9.375 15 9.65625 15 10V16.25Z"
        fill="#50BEE8"
      />
      <path
        d="M2.36648 14.9996C2.20148 14.9996 2.02148 14.909 2.02148 14.6896V11.8515C2.02148 11.6715 2.20148 11.5415 2.36648 11.5415H3.51086C5.79461 11.5415 5.74398 14.9996 3.55523 14.9996H2.36648ZM2.68148 12.1509V14.3896H3.51086C4.86023 14.3896 4.91961 12.1509 3.51086 12.1509H2.68148Z"
        fill="white"
      />
      <path
        d="M7.65635 15.0499C6.73197 15.0899 5.77197 14.4749 5.77197 13.2505C5.77197 12.0211 6.73135 11.4167 7.65635 11.4167C8.53073 11.4611 9.4451 12.0661 9.4451 13.2505C9.4451 14.4355 8.53073 15.0499 7.65635 15.0499ZM7.60135 12.0667C7.04135 12.0667 6.43197 12.4617 6.43197 13.2511C6.43197 14.0361 7.04197 14.4361 7.60135 14.4361C8.17635 14.4361 8.79073 14.0361 8.79073 13.2511C8.79073 12.4611 8.17635 12.0667 7.60135 12.0667Z"
        fill="white"
      />
      <path
        d="M10.0337 13.2453C10.0337 12.2809 10.6387 11.4515 11.7881 11.4515C12.2231 11.4515 12.5681 11.5815 12.9324 11.8965C13.0674 12.0209 13.0824 12.2409 12.9474 12.3809C12.8124 12.5003 12.6074 12.4859 12.4874 12.3659C12.2831 12.1509 12.0731 12.0915 11.7881 12.0915C11.0187 12.0915 10.6493 12.6365 10.6493 13.2459C10.6493 13.8659 11.0137 14.4353 11.7881 14.4353C12.0731 14.4353 12.3381 14.3197 12.5681 14.1153C12.7224 13.9953 12.9374 14.0547 13.0274 14.1753C13.1074 14.2853 13.1468 14.4703 12.9724 14.6447C12.6224 14.9703 12.2031 15.0353 11.7874 15.0353C10.5787 15.0353 10.0337 14.2097 10.0337 13.2453Z"
        fill="white"
      />
      <path
        d="M14.375 16.875H2.5V17.5H14.375C14.7188 17.5 15 17.2188 15 16.875V16.25C15 16.5938 14.7188 16.875 14.375 16.875Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};

export default Doc;

import React from 'react';
export default function ChevronRight({ color = '#8F92A1', width = '6px', height = '10px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333008 1.27619L1.27587 0.333374L5.98987 5.04743L1.27587 9.76149L0.333008 8.81863L4.10427 5.04743L0.333008 1.27619Z"
        fill={color}
      />
    </svg>
  );
}

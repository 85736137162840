import { useEffect, useState } from 'react';

export default function getWindowSize() {
  const [size, setSize] = useState<number[]>([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
